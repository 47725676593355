<template>
  <div class="overflow-hidden h-100 w-100">
    <v-container class="pb-5 page-content" fluid>
      <v-img
        :src="require('@/assets/bg_admin_profile.png')"
        :width="
          $vuetify.breakpoint.smAndDown
            ? 'calc(100% - 24px)'
            : 'calc(100% - 324px)'
        "
        :aspect-ratio="$vuetify.breakpoint.smAndDown ? 414 / 180 : 1524 / 212"
        class="rounded-lg rounded-b-0 position-absolute"
      />
      <div
        class="d-flex"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top: calc((100vw - 324px) * 212 / 1524 - 100px);'
            : 'margin-top: calc((100vw - 24px) * 212 / 1524 - 20px)'
        "
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <div
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'width: 260px; min-width: 260px; z-index: 1; margin-top: 60px;'
              : 'width: 100%; margin-top: 60px; z-index: 1;'
          "
          class="d-flex flex-column align-center bg-white rounded-bl-lg"
        >
          <editable-avatar
            :img="profile.image"
            :size="120"
            :editable="true"
            :onImageSelected="onImageSelected"
            style="margin-top: -60px;"
          />
          <div class="mt-5 app-bold-font font-20 mb-3">
            {{ profile.first_name }} {{ profile.last_name }}
          </div>
          <div>
            {{ profile.email }}
          </div>
          <div>
            {{ profile.phone }}
          </div>
        </div>
        <div class="flex-grow" style="margin-top: 60px;">
          <v-card class="rounded-lg rounded-l-0 rounded-tr-0" flat>
            <v-tabs v-model="tab" align-with-title left>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab v-for="item in tabMenus" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabMenus" :key="item">
                <v-card flat v-if="item == 'Information'">
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          label="First Name"
                          dense
                          outlined
                          v-model="profile.first_name"
                          background-color="white"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          label="Second Name"
                          v-model="profile.last_name"
                          background-color="white"
                          dense
                          outlined
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Email"
                          type="email"
                          dense
                          outlined
                          background-color="white"
                          v-model="profile.email"
                          :rules="[rules.required, rules.email]"
                        />
                      </v-col>
                      <v-col cols="8">
                        <AppPhoneNumberInput
                          v-model="profile.phone"
                          @update="onUpdatePhone"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          hide-details
                          label="Ext"
                          dense
                          outlined
                          v-model="profile.phone_ext"
                          background-color="white"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Address"
                          dense
                          outlined
                          v-model="profile.address"
                          background-color="white"
                          :rules="[rules.required, rules.phone]"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Address 2"
                          dense
                          outlined
                          v-model="profile.address2"
                          background-color="white"
                          :rules="[rules.required, rules.phone]"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          hide-details
                          label="City"
                          dense
                          outlined
                          v-model="profile.city"
                          background-color="white"
                          :rules="[rules.required, rules.phone]"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          hide-details
                          :items="stateOptions"
                          label="State"
                          dense
                          outlined
                          v-model="profile.state"
                          background-color="white"
                          :rules="[rules.required, rules.phone]"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          hide-details
                          label="Zip"
                          dense
                          outlined
                          v-model="profile.zip"
                          background-color="white"
                          :rules="[rules.required, rules.phone]"
                          type="text"
                          autocomplete="off"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          color="#0a5b8a"
                          class="px-15"
                          dark
                          @click="updateProfile"
                        >
                          Update Basic Information
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat v-else-if="item == 'Change Password'">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Current Password"
                          type="password"
                          dense
                          outlined
                          autocomplete="new-password"
                          v-model="oldPassword"
                          :rules="[
                            rules.required,
                            rules.min,
                            rules.passwordMatch,
                          ]"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="New Password"
                          type="password"
                          dense
                          outlined
                          autocomplete="new-password"
                          v-model="newPassword"
                          :rules="[
                            rules.required,
                            rules.min,
                            rules.passwordMatch,
                          ]"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Confirm Password"
                          type="password"
                          dense
                          outlined
                          autocomplete="new-password"
                          v-model="confirmPassword"
                          :rules="[
                            rules.required,
                            rules.min,
                            rules.passwordMatch,
                          ]"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          color="#472583"
                          class="mt-3"
                          dark
                          @click="updatePassword"
                        >
                          Update Password
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
      <v-overlay :value="loading">
        <v-container fluid fill-height>
          <v-layout justify-center align-center>
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import AppPhoneNumberInput from "@/components/common/AppPhoneNumberInput.vue";

export default {
  components: { EditableAvatar, AppPhoneNumberInput },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 6) || "Min 6 characters",
        passwordMatch: () =>
          this.newPassword != this.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI",
      ],
      phonePayload: {},
      loading: false,
      selectedFile: null,
      errorMessage: null,
      snackbar: false,
      tab: null,
      tabMenus: ["Information", "Change Password"],
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
    };
  },
  methods: {
    ...mapActions("auth", {
      fetchProfile: "fetchProfile",
      updateAdmin: "updateAdmin",
    }),
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    },
    onSelectEvent(ad) {
      console.log(ad);
    },
    updateProfile() {
      var formData = new FormData();
      this.loading = true;
      Object.keys(this.profile).map((key) => {
        if (this.profile[key] != undefined)
          formData.append(key, this.profile[key]);
      });
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      this.updateAdmin(formData)
        .then(() => (this.loading = false))
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    updatePassword() {
      if (
        this.newPassword &&
        this.oldPassword &&
        this.confirmPassword &&
        this.newPassword == this.oldPassword
      ) {
        this.loading = true;
        this.updateAdminPassword({
          newPassword: this.newPassword,
          oldPassword: this.oldPassword,
        })
          .then(() => (this.loading = false))
          .catch((error) => {
            this.loading = false;
            if (error.response == undefined) {
              console.log(error);
            } else {
              this.showErrorMessage(error.response.data.message);
            }
          });
      } else if (!this.oldPassword) {
        this.showErrorMessage("Please input the current password");
      } else if (!this.newPassword) {
        this.showErrorMessage("Please input the new password");
      } else if (!this.confirmPassword) {
        this.showErrorMessage("Please input the confirm password");
      }
    },
    onImageSelected(image) {
      this.selectedImage = image;
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  created() {
    this.loading = true;
    this.fetchProfile()
      .then(() => (this.loading = false))
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style scoped>
.agency-title {
  color: #472583;
  font-size: 30px;
  font-weight: bold;
  width: 120px;
  height: 40px;
  min-height: 40px;
  display: flex;
  padding-top: 10px;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.product-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.category-title {
  font-size: 32px;
  color: #472583;
  font-weight: 500;
}
.subtitle {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
.v-text-field--filled :deep(.v-input__control) {
  border-color: #4725835b;
  border-width: 2px;
  border-style: solid;
}
</style>
